import React from "react"
import Layout from "../components/layout"
import Content from "../components/content"
import {Link} from "gatsby"
import aboutStyles from "./about.module.scss"
import SEO from "../components/seo"


export default () => 
    <div className = {aboutStyles.background}>
        <Layout>
            <SEO
            title={"About Phil Cohn"}
            description={'Discover what freelancer Phil Cohn can do for your business.'}
            image={'/static/favicon.ico'}
        />
            <Content>
                <h1>About Me</h1>
                <p>
                I design and develop interactive mixed-reality experiences. My areas of expertise are Augmented Reality, 3D and 2D illustration. </p>        
                <p>Let me help you utilise the power of Snapchat lenses/Instagram effects as well as custom 2D/3D illustrations to take your brand to the next level.</p>
                <div className = {aboutStyles.links}>
                    <Link 
                        className = {aboutStyles.storyButton}
                        to={`/mystory/`}
                        >
                        My Story
                    </Link>
                    <Link
                        className = {aboutStyles.skillsButton}
                        to={`/skills/`}
                        >
                        My Skills
                    </Link>
                </div>
            </Content>
        </Layout>
    </div>

